import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forbbiden',
  templateUrl: './forbbiden.component.html',
  styleUrls: ['./forbbiden.component.css']
})
export class ForbbidenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
