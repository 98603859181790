<div class="container-md text-center img-container">
        <div class="row">
            <div class="col-md-8 img-principal">
                <img src="assets/imagen/anjade_imagen_principal.jpg" alt="Anjade Imagen" class="img-fluid">
            </div>
        </div>
    <div class="container-md  py-4">
        <div id="descripcionSiglas">
            <h1 class="titulo-principal">Asociación Nacional de Jueces y Árbitros del Deporte Español</h1>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <button class="btn btn-primary" id="botonRegistro" (click)="irARegistro()">¡Afíliate!</button>
    </div>
    <div class="container-md py-4" id="carta-presentacion">
        <div class="card" >
            <div class="card-body">
                <div class="section-header">
                    <h1 class="titulo-cartas">Carta de Presentación</h1>
                </div>
                <div>
                    <p class="texto-carta">
                        Bienvenido/a a la Web de la Asociación Nacional de Jueces y Árbitros del Deporte Español-
                        Dignidad
                        Deportiva (ANJADE)
                    </p>
                    <p class="texto-carta">
                        ANJADE nace para dar servicio a todos sus afiliados de las diferentes Federaciones Deportivas.
                        Fue registrada en el Ministerio del Interior, el 7 de marzo de 2024, y presentada en el
                        Auditorio
                        Samaranch del Consejo Superior de Deportes el 18 de abril y al Presidente del Comité Olímpico
                        Español con fecha 8 de mayo.
                    </p>
                    <p class="texto-carta">
                        Uno de los principales motivos de la ASOCIACIÓN NACIONAL DE JUECES Y ÁRBITROS- DIGNIDAD
                        DEPORTIVA-
                        en adelante (ANJADE), es defender los derechos de sus afiliados ante cualquier circunstancia que
                        vaya contra la honorabilidad de los jueces/as y árbitros/as, como deportistas y personas.
                        También,
                        atender a todos los que ejerzan otras actividades relacionadas con el arbitraje deportivo.
                    </p>
                    <p class="texto-carta">
                        ANJADE, tendrá una representación, como mínimo, en cada comunidad y con un departamento jurídico
                        para defensa de sus afiliados, sin coste alguno.
                    </p>
                    <p class="texto-carta">
                        A través de la web y las RRSS tendremos informados a los afiliados, de todos los acontecimientos
                        que
                        surjan en cada Federación, Comités Técnicos y en las Territoriales, así como de diversas
                        actividades
                        formativas.
                    </p>
                    <p class="texto-carta">
                        La Junta Directiva está formada por exárbitros/as y exjueces/as de distintas disciplinas
                        deportivas,
                        en algunos casos Internacionales y Olímpicos.
                        Deseamos que en la Junta Directiva de la ANJADE la integre un representante de cada disciplina
                        deportiva.
                    </p>
                    <p class="texto-carta">

                        Queremos ser una Asociación que sirva para unir y fortalecer a jueces/as y árbitros/as en todos
                        los
                        aspectos, cooperando así mismo con las diversas Federaciones y Organismos Deportivos.
                        Gracias por su visita
                    </p>

                </div>
            </div>
        </div>
    </div>
    <div class="container-md">
        <div class="card" id="carta-arbitros">
            <div class="card-body">
                <div class="section-header">
                    <h1 class="titulo-cartas">Carta a Arbitros(a) y Jueces(a)</h1>
                </div>
                <div>
                    <p class="texto-carta">Estimados compañeros y compañeras:</p>
                    <p class="texto-carta">
                        La tan añorada y deseada Asociación de Jueces y Árbitros ya está aquí. Fue registrada en el
                        Ministerio De Interior el día 7 de marzo de 2024, con el número: GEGAGE 24e00017773689, con los
                        correspondientes Estatutos
                        Se presento en el CONSEJO SUPERIOR DE DEPORTES y también en el Comite Olímpico Español.
                        Así mismo está siendo dada a conocer a las Federaciones, a sus Comités Nacionales de Jueces y
                        Árbitros, así como a otras Instituciones Deportivas.

                    </p>
                    <p class="texto-carta">
                        Uno de los principales objetivos de la ASOCIACIÓN NACIONAL DE JUECES Y ÁRBITROS-DIGNIDAD
                        DEPORTIVA,
                        en adelante (ANJADE), es defender los derechos de sus afiliados ante cualquier circunstancia que
                        vaya contra la honorabilidad de los jueces y árbitros, como deportistas y personas.
                        La Asociación tiene como principios la defensa del buen nombre de los asociados, la mejora de la
                        imagen colectiva, incluidos delegados, informadores, directivos y todo aquel que participe en la
                        Asociación. La defensa en el Deporte Base ante agresiones, insultos, vejaciones, sobre todo a
                        menores, totalmente intolerables.

                    </p>
                    <p class="texto-carta">
                        En. La Junta Directiva están representadas diferentes modalidades deportivas. Dentro de la
                        misma,
                        esta la Comisión Ejecutiva, compuesta actualmente por 2 representantes de Fútbol, 1 de
                        Atletismo, 1
                        de Balonmano, 1 de Hockey sobre Hielo, 1 de Taekwondo, 1 de Voleibol y 1 de Waterpolo.

                    </p>
                    <p class="texto-carta">
                        La Asociación tendrá una representación, como mínimo, en cada Comunidad y, además, con un
                        departamento jurídico para ayudaros en temas legales/deportivos, sin coste alguno.

                    </p>
                    <p class="texto-carta">
                        Os defenderemos ante agresiones, insultos sin ética deportiva y falsedades, qué deterioran
                        vuestra
                        imagen como deportistas y personas, también para defender a las Instituciones que representamos
                        e
                        intervenir para mediar en conflictos.

                    </p>
                    <p class="texto-carta">
                        ¿Que os ofrece ANJADE-DIGNIDAD DEPORTIVA?
                        La Asociación estará presente siempre donde nos necesiten nuestros Asociados o Instituciones
                        deportivas.
                        La creación de una red de opinión y quejas para la defensa de los derechos de los afiliados/as.
                        Fomentar las reuniones, por zonas, de los jueces y árbitros, para tener una opinión mas cercana
                        sobre temas de actualidad y poder valorar mejor los pasos que se deben de dar.
                        Asistencia inmediata ante situaciones que necesiten nuestra colaboración y presencia, tanto
                        jurídica, como de la Asociación

                    </p>
                    <p class="texto-carta">
                        La Asociación dará cursos de formación y actualización sobre las materias que intervienen en el
                        desarrollo de las diversas actividades de sus asociados.
                        Cursos de primeros auxilios ante la falta de recursos sanitarios en algunas Competiciones
                        Territoriales.
                        También proporcionaremos, a través de la página Webb, recomendaciones sobre diversos aspectos
                        del
                        rendimiento deportivo para árbitros y jueces deportivos (Condición física, psicología, lenguaje
                        corporal, nutrición, etc.).

                    </p>
                    <p class="texto-carta">
                        Fomentar las reuniones con otras Asociaciones nacionales y extranjeras, para participar en
                        competiciones de diferentes deportes y, a la vez, colaborar para mejorar la imagen colectiva de
                        jueces/as y árbitros/as.

                        Realizaremos entrevistas de opinión a personajes destacados de nuestros deportes y que sirvan de
                        referencia a nuestros jueces y árbitros.

                    </p>
                    <p class="texto-carta">
                        Queremos ser una Asociación que sirva para unir y conocer en todos los aspectos las diferentes
                        disciplinas deportivas y su realidad, para tal fin, haremos un estudio de la situación de todos
                        los
                        Jueces y Arbitros de cada deporte a través de un Observatorio del Arbitraje.

                    </p>
                    <p class="texto-carta">
                        La Asociación está abierta a todo el que quiera colaborar, tanto desde las Territoriales, como a
                        nivel Nacional, ya que hay varias comisiones abiertas para tal fin.

                    </p>

                    <p class="texto-carta">
                        Empezamos en w.w.w.anjade.es, donde podrás encontrar poco a poco nuestra información y los datos
                        de
                        afiliación, así como, nuestro cuestionario, por si quieres colaborar. También nuestros números
                        de
                        contacto, correo-electrónico de cada Directivo y deporte.

                        Así mismo, estaremos presentes en las distintas RRSS.

                    </p>
                    <p class="texto-carta">
                        Esperamos que estés interesado en formar parte de ANJADE , lo comentes con tus compañeros y nos
                        podamos saludar próximamente de forma personal.

                        ANJADE NOS DARÁ LA FUERZA
                    </p>
                    <p class="texto-carta-firma">
                        El Presidente y la Junta Directiva de ANJADE DIGNIDAD-DEPORTIVA

                    </p>
                    <div class="mb-3 row">
                        <div class="col 3">
                            <p class="texto-carta-firma">
                                Presidente
                            </p>
                            <p class="texto-carta-firma">
                                Ildefonso Urizar de Azpitarte.
                            </p>
                        </div>
                        <div class="col 3">
                            <p class="texto-carta-firma">
                                Vicepresidente 1ro.
                            </p>
                            <p class="texto-carta-firma">
                                JMª García-Aranda.
                            </p>
                            
                        </div>
                        <div class="col 3">
                            <p class="texto-carta-firma">
                                Vicepresidente 2do.
                            </p>
                            <p class="texto-carta-firma">
                                Antonio Pérez.
                            </p>
                        </div>
                        <div class="col 3">
                            <p class="texto-carta-firma">
                                Secretaría
                            </p>
                            <p class="texto-carta-firma">
                                P.Ortiz de Mendibil.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
