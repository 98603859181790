<h2 mat-dialog-title class="center-text">Comentarios</h2>

<div mat-dialog-content>
  <div *ngIf="data.comentarios.length > 0; else noComments">
    <div *ngFor="let comentario of data.comentarios ">
      <div class="comentario">
        <div style="border: 1px solid #ccc; border-radius: 8px; padding: 16px; position: relative; max-width: 100%; margin-top: 20px;">
          <div style="position: absolute; top: -12px; left: 16px; background-color: white; padding: 0 8px; font-weight: bold; color: #333;">
            {{comentario.nombre }}
          </div>
          <p style="margin: 16px 0 0; font-size: 16px; color: #555;">
            {{comentario.texto || 'No hay comentarios disponibles.' }}
          </p>
        </div>
        <span class="fechaComentario">{{ comentario.fechaComentario | date:'short' }}</span>
        
      </div>
    </div>
  </div>
  
  <ng-template #noComments>
    <p>No hay comentarios para esta publicación.</p>
  </ng-template>

  
    <div *ngIf="crearComentario" class="mt-3">
      <textarea [(ngModel)]="comentarioTexto" class="form-control" rows="3"
          placeholder="Escribe tu comentario aquí"></textarea>
      <div *ngIf="!isUserLoggedIn" class="mt-2">
          <input [(ngModel)]="idAfiliacion" class="form-control"
              placeholder="Número de afiliación" />
      </div>
      <button class="btn btn-sm btn-success mt-2" (click)="agregarComentario(data.noticiaId)" [disabled]="isSendingComment">
        <mat-spinner *ngIf="isSendingComment" diameter="20" class="mr-2"></mat-spinner>Enviar
          comentario</button>
  </div> 
</div>

<div mat-dialog-actions class="button-container">
  <button class="btn btn-sm btn-secondary" (click)="toggleComentarioForm()">Agregar
    comentario</button>    
  <button class="btn btn-sm btn-primary cerrar" (click)="onClose()">Cerrar</button>
</div>
